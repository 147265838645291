import React, {  useState } from "react";
import linkData from './site_info.json';
import { Card ,Col, Row, Container, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import banner from "./images/ags_logo.png"

const categories = ["3D","2D","textures","spritesheet","music","SFX","github","itch.io","reference"];

const tagColor = {
  "3D" : "#c80000",
  "2D" : "#009dfb",
  "textures" : "#a600c2",
  "spritesheet" : "#0046bb",
  "music" : "#007b12",
  "SFX" : "#3b3b3b",
  "github" : "#646464",
  "itch.io" : "#ff4b00",
  "reference" : "#9c9800"
}

const getIcon = { 
  "github" : require('./icons/github-mark-white.svg').default,
  "itchio": require('./icons/itch-io.svg').default,
  "nasa": require('./icons/nasa.svg').default,
  "flickr": require('./icons/flickr.png'),
  "placeholder": require('./icons/placeholder.svg').default,
  "si": require('./icons/3dsi.png'),
  "artic": require('./icons/artic.svg').default,
  "freesound": require('./icons/freesound.png'),
  "getty": require('./icons/getty.png'),
  "nga": require('./icons/nga.png'),
  "sharetextures": require('./icons/sharetextures.png'),
  "themet": require('./icons/themet.png'),
  "themorgan": require('./icons/themorgan.png'),
  "vamac": require('./icons/vamac.png'),
  "yale": require('./icons/yale.png'),
  "wiki": require('./icons/wiki.png'),
  "oga": require('./icons/oga.png'),
  "wellcomecollections": require('./icons/wellcomecollection.png'),
  "davidrumsey": require('./icons/davidrumsey.png'),
  "soundbible": require('./icons/soundbible.png'),
  "kenneynl": require('./icons/kenneynl.jpg'),
  "ambientcg": require('./icons/ambientcg.png'),
  "synchedin": require('./icons/synchedin.png'),
  "textureninja": require('./icons/textureninja.png'),
  "pbrmaterials": require('./icons/pbrmaterials.png'),
  "chocofur": require('./icons/chocofur.png'),
  "julio": require('./icons/julio.png'),
  "market": require('./icons/marketpmndrs.png'),
  "polyhaven": require('./icons/polyhaven.png'),
  "quaternius": require('./icons/quaternius.png'),
  "europeana": require('./icons/europeana.png'),
  "calisphere": require('./icons/calisphere.png'),
  "britishmuseum": require('./icons/britishmuseum.svg').default,
  "rijk": require('./icons/rijk.png'),
  "lacma": require('./icons/lacma.png'),
}

function HorizontalCard({ iconName, url, tags, title, basicInfo}) {

  var iconPath = getIcon[iconName];

  return (

    <Card border = "light" style={{ borderWidth: '5px', backgroundColor: "#0f1112" }}> 
      <Row>

          <Card.Body className="d-flex flex-column">

            <Row className="d-flex justify-content-center align-items-center " >
              <Col xs={2} className="d-flex" >
                <Card.Img variant="top" src={iconPath} style={{ backgroundColor : "black"}} />
              </Col>

              <Col xs={9}>
                <Card.Title style={{ fontSize: '3rem' }}>{title}</Card.Title>
                <Card.Text style={{ fontSize: '1.5rem' }}>{basicInfo}</Card.Text>

              </Col>

            </Row>

            <Row className="d-flex justify-content-center text-center" >
              <div>
                <a style={{ fontSize: '1.5rem' }} href = {url} target="_blank" rel="noreferrer">{url}</a>
              </div>
              <div className="pt-3">
                {tags.map((tag, index) => (
                <span key={index} class="badge badge-pill" style = {{backgroundColor : tagColor[tag] , fontSize: '1rem' ,  marginRight: '0.5rem', marginBottom: '0.5rem'}}>
                  {tag}
                </span>
                ))}
              </div>

            </Row>

          </Card.Body>

      </Row>
    </Card>
  );
}


export default function App() {

  
  let [categoryFilters, setcategoryFilters] = useState(new Set());

  function updateFilters(checked, categoryFilter) {
    if (checked)
      setcategoryFilters((prev) => new Set(prev).add(categoryFilter));
    if (!checked)
      setcategoryFilters((prev) => {
        const next = new Set(prev);
        next.delete(categoryFilter);
        return next;
      });
  }

  const filteredProducts =
  categoryFilters.size === 0
    ? linkData
    : linkData.filter((p) =>
        [...categoryFilters].every(category => p.tags.includes(category))
      );

  return (

   
    <div>

      <Container className="w-4/5 lg:w-2/5">
        <Row className="pb-5 d-flex justify-content-center" style = {{backgroundColor : "#0f1112" }}>

          <Image src={banner}  className="img-fluid" style={{ maxWidth: "30%" }} />
          <h1 className="display-1 d-flex flex-wrap gap-3 justify-content-center fw-bold">Art Game Sound</h1>
        </Row>

          <Row className="d-flex flex-wrap gap-3 justify-content-center pt-5" style={{ fontSize: '3rem'  }} >
            Filters
          </Row>

          <Row className="pb-3" >        
            <div className="d-flex flex-wrap gap-1 justify-content-center">
              {categories.map((elm, index) => {
                return (
                  <div className="form-check ms-2" key={index}>
                    <label className="form-check-label" >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) => updateFilters(e.target.checked, elm)}
                      />
                      {elm}
                    </label>
                  </div>
                );
              })}
            </div>
          </Row>



        <Row xs={1} className="g-3 pt-2" >
          {filteredProducts.map((prod, index) => {
            return (
            
              <Col key = {index}>

                <HorizontalCard iconName={prod.icon} url = {prod.url} tags = {prod.tags} title = {prod.title} basicInfo={prod.info}/>

              </Col>
            );
          })}
        </Row>


        <Row className="pt-4">
          <h4>
            This a collection of websites that contain either free, public domain, Creative Commons Zero (CC0), or royalty free game assets that don't require attribution.
            <b> I can't guarantee that any of the content from these websites is actually Public Domain, CC0, or royalty free, they providing content and claiming it is either of those things.
              Make sure to read the license agreement of any of assets you plan to use in your projects and give attribution if required.
            </b>


          </h4>

          <Row className="pt-4">
              <h4>3D Art</h4>
              <p>Free 3D art, free 3D models, low-poly 3D models, 3D scans of objects, reference images for 3D modelling </p>

              <h4>2D Art</h4>
              <p>Free 2D art, photographs, illustration, sketches, spritsheets for 2D animation, reference images, refrence photos, textures, and materials.</p>
     
              <h4>Sounds</h4>
              <p>Free sounds and music for games. Game sound effects(SFX), music loops, ambient sound loops, Sci-Fi sounds, foley, free music, free sound effects for games,
              </p>

              <h4>Asset packs</h4>
              <p>Free game asset packs, low-poly asset packs, 3D model asset packs, 2D art asset packs, combo asset packs</p>

          </Row>


        </Row>



      </Container>
      <footer className="footer">
        <Container>
          <p className="text-center mb-0"><i>By : @permaonline</i></p>
        </Container>
      </footer>
    </div>

  );
}

